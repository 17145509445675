import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from "@common/Buttons";
import RadioButton from "@common/RadioButton";
import { COMMON_TEXT } from "@containers/BingeLogin/APIs/constants";
import "./style.scss";
import { ACCOUNT_STATUS } from "@containers/BingeLogin/APIs/constants";
import { notNow, licenceAgreement } from "@containers/Login/LoginCommon";
import get from "lodash/get";
import { withRouter } from 'react-router-dom';
import brandingLogo from "@src/Json_images/MultiChoice-logo.png";

class MultipleSubscriptions extends Component {
    componentDidMount = () => {
        // set check fisrt
        let { subscriptionsList, radioButtonClickHandler } = this.props;
        if (subscriptionsList && subscriptionsList.length > 1) {
                radioButtonClickHandler('selectedAccount', subscriptionsList[0]?.accountDetailsDTOList[0])
                radioButtonClickHandler('selectedSubscriber', subscriptionsList[0])
            }
    }
    render() {
        let { barStatus, touchStatus, subscriptionsList, selectedSubscriber, handleProceedBtn, configResponse, radioButtonClickHandler, selectedAccount } = this.props;
        let bingClass, bingText = '';
        const isMobilecheck = /Mobi|Android|iPhone|Tablet|iPad|iPod/i.test(navigator.userAgent);
        const ishrefOnMobile = isMobilecheck ? "tel:16442" : "";
        return (
            <React.Fragment>
                {/* <p className='subscriber-list-header'>{get(configResponse, "data.config.ios_freetrial_title")}</p> */}
                <p className='subscriber-list-header'>{get(configResponse, "data.config.genericVerbiages.android.loginJourneyScreens.subIdScreen.title")}</p>
                <div className='subscriber-list-wrapper'>
                    {subscriptionsList.map((subscriptionsItem, index) => {
                        if (subscriptionsItem?.accountDetailsDTOList.length == 0) {
                            bingText = '';
                            bingClass = 'hidden';
                        }
                        else if (subscriptionsItem?.accountStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE) {
                            bingText = 'Active';
                            bingClass = 'active-akash';
                        }
                        else if (subscriptionsItem?.accountStatus?.toUpperCase() === ACCOUNT_STATUS.DEACTIVATED) {
                            bingText = 'Inactive';
                            bingClass = 'inactive-akash';
                        }
                        else {
                            bingText = 'Active';
                            bingClass = 'active-akash';
                        }
                        return (
                            <div className="subscriber-list-container" key={index}>
                                <span className="subscriber-list-id">Sub ID: {subscriptionsItem.subscriberId}</span>
                                <ul className={`subscriber-list
                            ${barStatus === false && !touchStatus && subscriptionsList?.length > 4 ? `sub-swipe-screen` : null}`}>
                                    {/* {item.accountDetailsDTOList.length} */}
                                    {subscriptionsItem?.accountDetailsDTOList.map((item, index) => {

                                        const isChecked = selectedSubscriber.subscriberId === subscriptionsItem.subscriberId && selectedAccount.baId === item.baId
                                        console.log("log--1", isChecked);

                                        return (
                                            <li key={index} className={isChecked && "active"}>
                                                <div className='sudId-pack'>
                                                    <RadioButton
                                                        id={subscriptionsItem.subscriberId}
                                                        name={subscriptionsItem.subscriberId}
                                                        value={subscriptionsItem.subscriberId}
                                                        chandler={() => {
                                                            radioButtonClickHandler('selectedSubscriber', subscriptionsItem),
                                                                radioButtonClickHandler('selectedAccount', item)
                                                        }
                                                        }
                                                        showLabel={false}
                                                        checked={isChecked}
                                                    />
                                                    <span className='subscription-id'>{item.packName}</span>
                                                </div>

                                                <div className='sudId-wrapper'>
                                                    {/* <span className='subscription-id'>{item.packName}</span> */}
                                                    <span className={bingClass}>{bingText}</span>
                                                </div>

                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>

                        )
                    })}
                    {/* <div className='button-container'>
                    <Button
                        cName={`btn primary-btn login-btn`}
                        bType="button"
                        bValue='Submit'
                        clickHandler={handleProceedBtn}
                        disabled={!selectedSubscriber}
                    />
                </div> */}
                </div>
                {/* previous code */}
                {/* <ul className={`subscriber-list 
                 ${barStatus === false && !touchStatus && subscriptionsList?.length > 4 ? `sub-swipe-screen` : null}`}>
                    {subscriptionsList.map((item, index) => {
                        if (item?.accountDetailsDTOList.length == 0) {
                            bingText = '';
                            bingClass = 'hidden';
                        }
                        else if (item?.accountStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE) {
                            bingText = 'Akash Active';
                            bingClass = 'active-binge';
                        }
                        else if (item?.accountStatus?.toUpperCase() === ACCOUNT_STATUS.INACTIVE) {
                            bingText = 'Akash Inactive';
                            bingClass = 'inactive-binge';
                        }
                        else {
                            bingText = 'Akash Active';
                            bingClass = 'active-binge';
                        }
                        return (
                            <li key={index}>
                                <div className='sudId-wrapper'>
                                    <span className='subscription-id'>{item.subscriberId}</span>
                                    <span className={bingClass}>{bingText}</span>
                                </div>
                                <div>
                                    <RadioButton
                                        id={item.subscriberId}
                                        name={item.subscriberId}
                                        value={item.subscriberId}
                                        chandler={() => radioButtonClickHandler('selectedSubscriber', item)}
                                        showLabel={false}
                                        checked={selectedSubscriber.subscriberId === item.subscriberId && selectedSubscriber.dthStatus === item.dthStatus}
                                    />
                                </div>
                            </li>
                        )
                    })}
                </ul> */}
                {/* <div className={"multipleSubscription-login-license"}>
                    <p>
                        {get(configResponse, "data.config.licenseAgreement.title")}
                        <span className={"font-14"}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={"/termsConditions.html"}
                                onClick={licenceAgreement}
                            >
                                {COMMON_TEXT.LICENCE_AGREEMENT}
                            </a>
                        </span>
                    </p>
                </div> */}
                <div className='button-container'>
                    <Button
                        cName={`btn primary-btn login-btn`}
                        bType="button"
                        bValue='Submit'
                        clickHandler={handleProceedBtn}
                        disabled={!selectedSubscriber}
                    />
                </div>
                {/* <div className="sub-not-now" onClick={() => notNow(this.props)} >
                    Not now
                </div> */}
                {/* <div className="loginMdalFooter">
                    <p>Not an <span className={"blue-text font-16"}><a href="https://www.akashdth.com" target="_blank" className={"blue-text font-14"}>AKASH DTH</a></span> user yet?</p>
                    <p>Call <span className={"blue-text font-16"}>{isMobilecheck ? <a href={ishrefOnMobile} className={"blue-text font-14"}>16442</a> : "16442"}</span> or visit <span className={"blue-text font-14"}><a href="https://www.akashdth.com" target="_blank" className={"blue-text font-14"}>www.akashdth.com</a></span></p>
                </div> */}
            </React.Fragment>
        )
    }
}
MultipleSubscriptions.propTypes = {
    radioButtonClickHandler: PropTypes.func,
    handleProceedBtn: PropTypes.func,
    closeLoginModel: PropTypes.func,
    subscriptionsList: PropTypes.array,
    selectedSubscriber: PropTypes.object,
    barStatus: PropTypes.bool,
    touchStatus: PropTypes.bool,
    configResponse: PropTypes.object,
}

export default withRouter(MultipleSubscriptions);