import React from "react";
import "shaka-player/dist/controls.css";
import {
  Player as shaka,
  ui as ShakaUI,
  polyfill as ShakaPolyfill,
} from "shaka-player/dist/shaka-player.ui";
import "./ShakaPlayer.scss";
import firebase from "@utils/constants/firebase";
import { MINI_SUBSCRIPTION } from "@utils/constants";
import { safeNavigation, isUserloggedIn, loginInFreemium } from "@utils/common";
import { MODALS } from "@common/Modal/constants";
import { URL } from "@routeConstants";
import { hideSearchOfEpisode } from "@src/action";
import { FaAngleUp } from "react-icons/fa";
import MovieListing from "@common/CastAndCrew/MovieList";
import axios from "axios";

class ShakaPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    // this.timerRef = React.createRef(null);
    this.player = null;
    // this.playerRef = React.createRef();
    this.onErrorEvent = this.onErrorEvent.bind(this);
    this.onError = this.onError.bind(this);
    this.playerCallback = props.playerCallback;
    this.duration = props.playerData?.detail?.duration;
    this.timeInterval = 1;
    this.playerState = "PLAY";
    this.prevTime = 0;
    this.progress = 0;
    this.state = {
      castAndCrewData: [],
      playerState: "PLAY",
      favStatus: true,
      title:
        props?.props?.detail?.contentType === "MOVIES"
          ? props?.props?.detail?.vodTitle || props?.props?.detail?.title
          : props?.props?.detail?.title,
      contentType: this.props?.props?.detail?.contentType,
    };
    this.intervalId = null;
    this.currentTime = 0;
    this.tmdbKey = "4ff3fa8b3313f4c683a658d241b61fbd";
    this.tmdbBaseUrl = "https://api.themoviedb.org";
    this.getMovieOrTvPage =
      this.props?.playerData?.detail?.contentType === "MOVIES"
        ? "movie"
        : this.props?.playerData?.detail?.contentType === "TV_SHOWS"
          ? "tv"
          : "";

    // console.log("abcd--", this.props);
  }

  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError(error) {
    // Log the error.
    console.error("Error code", error.code, "object", error);
  }

  escapeRegExp = (string) => {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
  };

  componentDidMount() {
    const { playerData } = this.props;
    const movieName =
      (playerData?.detail?.contentType === "MOVIES" ||
        playerData?.detail?.contentType === "TV_SHOWS") &&
      (playerData?.detail?.brandTitle ||
        playerData?.detail?.seriesTitle ||
        playerData?.detail?.vodTitle ||
        playerData?.detail?.title);
    const url = `${this.tmdbBaseUrl}/3/search/${this.getMovieOrTvPage}`;
    const options1 ={
      headers: {
        Authorization : `Bearer ${this.tmdbKey}`, 
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        api_key: `${this.tmdbKey}`,
        query: `${movieName}`
      }

    }
    const options2 ={
      headers: {
        Authorization : `Bearer ${this.tmdbKey}`, 
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        api_key: `${this.tmdbKey}`
      }

    }
    axios.get(url, options1).then((response) => {
      if (response?.data?.results.length > 0) {
        let idMv =
          response?.data?.results === 0
            ? response?.data?.results?.filter((movie) => {
              (movie.title || movie.name) === movieName;
              return movie.id;
            })
            : response?.data?.results
              ?.filter((movie) => {
                if (movieName === "") return true;
                const escaped = this.escapeRegExp(movieName);
                const re = new RegExp(`.*${escaped}$`);
                return re.test(movieName);
              })
              .map((movie) => [movie.id]);

        const url2 = `${this.tmdbBaseUrl}/3/${this.getMovieOrTvPage}/${idMv?.[0]}/credits`;
        axios.get(url2, options2).then((response) => {
          this.setState({ castAndCrewData: response.data?.cast });
        });
      } else {
        return null;
      }
    });

    this.initializePlayer();
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  getDuration = () => {
    const video = document.getElementById("whiteLabelShakaPlayer");
    // set duration which is recived by the props
    // this.duration = video?.duration ? video?.duration:0;
    this.duration = video?.duration ? video?.duration : this.props?.props?.detail?.duration;
    return this.duration;
  };

  getCurrentTime = () => {
    const video = document.getElementById("whiteLabelShakaPlayer");
    this.currentTime = video?.currentTime ? video.currentTime : 0;
    return this.currentTime;
  };

  async initializePlayer() {
    const video = document.getElementById("whiteLabelShakaPlayer");
    const videoContainer = document.getElementById("video-container");
    //  const playcontrols = document.getElementById('playercontainer-title');
    //  videoContainer.appendChild(playcontrols);
    const fullScreenTitle = document.getElementById("fullScreenTitle");

    this.duration = video.duration;
    this.player = new shaka(this.containerRef.current);
    window.shakaplayer = this.player;
    const uiConfig = {
      addBigPlayButton: false,
      doubleClickForFullscreen: false,
      controlPanelElements: [
        // 'play_pause',
        
        "mute",
        // "volume",
        "time_and_duration",
        "rewind_10",
        "forward_10",
        // "vertical_volume",
        // "overflow_menu",
        // "playback_rate",
        "captions",
        "language",
        "quality",
        "fullscreen"
      ],

      // overflowMenuButtons: [
      //   // "captions",
      //   // 'quality',
      //   // "language",
      //   // 'picture_in_picture',
      //   // "cast",
      //   // "playback_rate",
      // ],
      addSeekBar: true,
      seekBarColors: {
        base: "#ffffff",
        buffered: "#ffffff",
        played: "#ffffff",
      },
      enableTooltips: false, //shows info about controls when hovers
      addBigPlayButton: false,
      singleClickForPlayAndPause: false,
      // drm: {
      //   servers: {
      //     'com.widevine.alpha': 'https://widevine-proxy.drm.technology/proxy'
      //   },
      //   advanced: {
      //     'com.widevine.alpha': {
      //       videoRobustness: 'https://fairplay-license.drm.technology/certificate',
      //       audioRobustness: 'https://fairplay-license.drm.technology/license'
      //     }
      //   }
      // }
    };
    //rewind controls
    ShakaUI.Controls.registerElement("rewind_10", this.rewind());
    //fast forward controls
    ShakaUI.Controls.registerElement("forward_10", this.forward());

    const ui = new ShakaUI.Overlay(window.shakaplayer, videoContainer, video);
    ui.configure(uiConfig);
    ShakaPolyfill.installAll();

    let manifestUri = "";
    if (!this.props?.isTrailer) {
      manifestUri = this.props?.playerData?.playDetail?.playUrl;
    } else {
      manifestUri = this.props?.playerData?.playDetail?.trailerUrl;
    }
    // Disable double-click for fullscreen on the video element
    // video.removeEventListener('DBLCLICK', ui.fullscreenClickHandler_);
    // ui.doubleClickToFullscreen = false;
    document.addEventListener("dblclick", function (event) {
      // Your double-click handling logic here
      console.log("Double-click detected");
      event.preventDefault();
    });
    // Load the media stream
    await this.player
      .load(manifestUri)
      .then(() => { })
      .catch((error) => {
        console.error("Error loading stream:", error);
      });
    this.setState({ contentType: this.props?.playerData?.detail?.contentType });

    // Get references to the containers and the div to move
    const sourceContainer = document.querySelector(".play-controls");
    const sourceContainer1 = document.querySelector(".favIcon");
    const sourceContainer2 = document.querySelector(".headerwidth");
    const sourceContainer3 = document.querySelector(".castCrew");

    const destinationContainer = document.querySelector(
      ".shaka-controls-container"
    );
    destinationContainer.appendChild(
      sourceContainer,
      sourceContainer1,
      sourceContainer2,
      sourceContainer3
    );

    // Handle full-screen change event
    document.addEventListener('fullscreenchange', function () {
      if (document.fullscreenElement) {
        fullScreenTitle.className = "title"
        // Get references to the containers and the div to move
        const sourceContainer = document.querySelector(".play-controls");
        const sourceContainer1 = document.querySelector(".favIcon");
        const sourceContainer2 = document.querySelector(".headerwidth");
        const sourceContainer3 = document.querySelector(".castCrew");

        const destinationContainer = document.querySelector('.shaka-controls-container');

        // Move the div to the destination container
        destinationContainer.appendChild(sourceContainer);
        destinationContainer.appendChild(sourceContainer1);
        destinationContainer.appendChild(sourceContainer2);
        destinationContainer.appendChild(sourceContainer3);


      } else {
        // Exiting full-screen
        fullScreenTitle.className = "title_change"
      }
    });

    let timeoutId;
    document.addEventListener('mousemove', () => {
      clearTimeout(timeoutId);

      // Get references to elements
      const playerControl = document.getElementById('playcontrols');
      const playerControl2 = document.getElementById('below-custome-icon');
      const playerControl3 = document.getElementById('header');
      const playerControl4 = document.getElementById('headercrew');

      // Show elements
      playerControl && playerControl.classList.remove('hidden');
      playerControl2 && playerControl2.classList.remove('hidden');
      playerControl3 && playerControl3.classList.remove('hidden');
      playerControl4 && playerControl4.classList.remove('hidden');
      // Hide elements after timeout
      timeoutId = setTimeout(() => {
        playerControl && playerControl.classList.add('hidden');
        playerControl2 && playerControl2.classList.add('hidden');
        playerControl3 && playerControl3.classList.add('hidden');
        playerControl4 &&  playerControl4.classList.add('hidden');
      }, 3000); // Adjust the timeout value as needed (in milliseconds)
    });
    // Video player is loaded and ready to play
    this.performOperationBasedOnTime();
  }

  // Seek forward by 10 minutes
  forward() {
    const video = document.getElementById("whiteLabelShakaPlayer");
    if (!video.duration || video.duration < 10) {
      return;
    }

    if (video.currentTime + 10 < video.duration) {
      video.currentTime += 10;
    } else {
      // if forwarding exceeds video duration, move to last STEP (ie. 10) seconds
      video.currentTime = video.duration - 10;
    }
    // this.toggleFullscreen()
  }

  rewind() {
    const video = document.getElementById("whiteLabelShakaPlayer");
    if (!video.duration || video.duration < 10) {
      return;
    }

    if (video.currentTime - 10 > 0) {
      video.currentTime = video.currentTime - 10;
    } else {
      video.currentTime = 0;
    }
    // this.toggleFullscreen()
  }

  play = () => {
    this.setState({
      playerState: "PLAY",
    });
    const video = document.getElementById("whiteLabelShakaPlayer");
    video?.play();
    this.performOperationBasedOnTime();
    if (this.playerCallback) {
      this.playerCallback("PAUSE");
    }
  };

  pause = () => {
    this.setState({
      playerState: "PAUSE",
    });
    if (this.playerCallback) {
      this.playerCallback("PLAY");
    }
    clearInterval(this.intervalId);
    const video = document.getElementById("whiteLabelShakaPlayer");
    video?.pause();
  };

  watchListClick = async () => {
    let getSource = firebase.VALUE.PLAYBACK;
    if (isUserloggedIn()) {
      if (this.state.favStatus) {
        this.setState({ favStatus: false });
      } else {
        this.setState({ favStatus: true });
      }
      this.props?.playerFooter?.watchlistClick();
      this.props?.playerFooter?.controlsVisibility();
    } else {
      await loginInFreemium(
        this.props?.playerFooter?.openPopup,
        this.props?.playerFooter?.closePopup,
        this.props?.playerFooter?.openLoginPopup,
        { source: firebase.VALUE.PLAY_CLICK },
        getSource,
        { ComponentName: MINI_SUBSCRIPTION.PLAN_SELECT }
      );
    }
  };

  destroyPlayer() {
    // Clean up the player on component unmount
    if (this.player) {
      this.player.unload();
      this.player.destroy();
      this.player = null;
    }

    this.setState({
      playerState: "DESTROY",
    });
    clearInterval(this.intervalId);
  }
  performOperationBasedOnTime = () => {
    const video = document.getElementById("whiteLabelShakaPlayer");
    // let progressBar = 0;
    // if (video.duration <= this.getDuration()) {
    // 	progressBar = 100 - (video.duration / this.getDuration()) * 100
    // }
    // else if (video.duration > this.getDuration()) {
    // 	progressBar = 0;
    // }
    // this.progress = progressBar;
    // Video player is loaded and ready to play
    console.log("abcd---perform--");
    
    this.setState({
      favStatus:
        this.props?.props?.contentLastPlayedDetails
          ?.isFavourite,
    });
    this.setState({
      title:
        this.props?.props?.detail?.contentType === "MOVIES"
          ? this.props?.props?.detail?.vodTitle ||
          this.props?.props?.detail?.title
          : this.props?.props?.detail?.title,
    });

    if (this.props?.playerCallback != undefined) {
      // if (this.timerRef.current) {
      this.intervalId = setInterval(() => {
        if (this.state?.playerState == "PLAY") {
          if (location.href.includes('player')) {
          this.props?.playerCallback(
            "TIMEUPDATE",
            this.getDuration(),
            this.getCurrentTime(),
            10
          );

        }
        }
       }, 10000);

      // }
    }
  };

  // Function to toggle fullscreen manually
  //   toggleFullscreen() {
  //     if (document.fullscreenElement) {
  // 		document.exitFullscreen().catch((err) => {
  // 			console.error('Error exiting fullscreen:', err);
  // 		  });
  //     } else{
  // 		document.exitFullscreen().catch((err) => {
  // 			console.error('Error exiting fullscreen:', err);
  // 		  });

  // 	}
  //   }

  redirectToEpisodePage = () => {
    // let {history, mixpanelData, meta} = this.props;
    let { playerData, mixpanelData } = this.props;

    hideSearchOfEpisode(true);

    let mixpanelState = {
      title: this.props.playerData?.detail.brandTitle,
      railTitle: mixpanelData["RAIL-TITLE"],
      source: mixpanelData["SOURCE"],
      origin: "EDITORIAL",
      railPosition: mixpanelData["RAIL-POSITION"],
      conPosition: mixpanelData["CONTENT-POSITION"],
      sectionSource: "EDITORIAL",
      configType: "Editorial",
      sectionType: "RAIL",
    };
    console.log("redirect---", this.props, mixpanelState);

    safeNavigation(playerData?.history, {
      // pathname: `/${URL.EPISODE}/${meta.contentType}/${meta[contentType(meta.contentType) + "Id"]
      pathname: `/${URL.EPISODE}/${"SERIES"}/${playerData?.detail?.seriesId}`,
      state: {
        ...mixpanelState,
        seriesId:
          this.state.currentSeason === ""
            ? playerData?.detials.seriesId
            : this.state.currentSeason,
      },
      search: this.props?.location?.search,
      hideSearchOfEpisode: true,
    });
  };

  castAndCrew = (history) => {
    const { playerData } = this.props;
    
    playerData?.openPopup(MODALS.CUSTOM_MODAL, {
      modalClass:
        "alert-modal cast-and-crew-container language-selection-container gradiant-bg1 movie-container",
      childComponent: (
        <MovieListing castAndCrewData={this.state.castAndCrewData} />
      ),
      closeModal: true,
      hideCloseIcon: false,
      isCloseModal: true
    });
  };

  

  render() {
    return (
      <>
        <div className="ShakaPlayer" id="video-container">
          <video
            id="whiteLabelShakaPlayer"
            ref={this.containerRef}
            autoPlay={true}
            poster=""
            style={{
              width: "100%",
              height: "100%",
              "object-fit": "cover",
            }}
          />
          <div id="fullScreenTitle"></div>
        </div>
        {this.state.castAndCrewData?.length &&
          (this.state.contentType === "MOVIES" ||
            this.state.contentType == "TV_SHOWS") && (
            <div className="castCrew" id="headercrew">
              <i
                className="info-icon info-icon-castCrew"
                onClick={() => this.castAndCrew()}
              ></i>
            </div>
          )}
        <div className="favIcon" id="below-custome-icon">
          {this.state.contentType == "TV_SHOWS" && (
            <>
              <FaAngleUp
                style={{
                  color: "white",
                  fontSize: "2rem",
                  position: "relative",
                  top: "0.25rem",
                  left: "0.7rem",
                }}
              />
              <i
                className="view-all"
                onClick={() => this.redirectToEpisodePage()}
              >
                Series & Episode
              </i>
            </>
          )}
          <i
            className={`${this.state.favStatus ? "icon-fav" : "icon-star-empty"
              }`}
            onClick={() => this.watchListClick()}
          ></i>
        </div>
      </>
    );
  }
}

export default ShakaPlayer;
