import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { isMobile, isSubscriptionDiscount } from "@utils/common";
import InputBox from "@common/InputBox";
import Button from "@common/Buttons";
import { LENGTH_CHECK } from "@utils/constants";
import { COMMON_TEXT } from "@containers/BingeLogin/APIs/constants";
import { compose } from "redux";
import { withRouter } from "react-router";
import "./style.scss";
import { notNow, licenceAgreement } from "@containers/Login/LoginCommon";
import { URL } from "@utils/constants/routeConstants";

class RMNComponent extends Component {
  onEnterClick = (event) => {
    let { rmn, getOtpIsDisabled } = this.props;
    const { charCode } = event;
    const isValidRmnLenght = rmn?.startsWith("0") ? rmn.length === 11 : rmn.length === 10;
    // if rmn start with 0 then check length 11 otherwise length is equal 10
    if (charCode === 13 && isValidRmnLenght && !getOtpIsDisabled) {
      this.props.handleGetOtpClick();
    }
  };

  render() {
    const {
      rmn,
      handleChange,
      handleGetOtpClick,
      getOtpIsDisabled,
      configResponse,
      rmnError,
    } = this.props;

    const isMobilecheck = /Mobi|Android|iPhone|Tablet|iPad|iPod/i.test(navigator.userAgent);
    const ishrefOnMobile = isMobilecheck ? "tel:16442" : "";

    return (
      <div
        className={`rmn-input-container ${rmnError && "incorrect-input-container"
          }`}
      >
        <InputBox
          inputType={"tel"}
          initialVal={"+880"}
          name="rmn"
          value={rmn}
          isNumericWithZero
          // maxLength={textLength ? textLength : LENGTH_CHECK.RMN}
          onChange={handleChange}
          placeholder="Mobile No."
          errorMessage={rmnError}
          autoFocus={!isMobile.any()}
          onKeyPress={(e) => this.onEnterClick(e)}
        />
        <div className={"login-license"}>
          <p>
            {get(configResponse, "data.config.licenseAgreement.title")}
            {/* <span className={"blue-text font-14"}>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={get(configResponse, "data.config.termConditionPrivacy.privacyPolicyUrl")}
                    onClick={licenceAgreement}
                  >
                    {COMMON_TEXT.DATA_PRIVACY}
                  </a> 
                </span>
                 &nbsp; and  &nbsp; */}
            <span
              // className={"blue-text font-14"}
              className={"font-14"}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"/termsConditions.html"}
                onClick={licenceAgreement}
              >
                {COMMON_TEXT.LICENCE_AGREEMENT}
              </a>
            </span>
          </p>
        </div>
        <div className="button-container">
          <Button
            cName={`btn get-otp-btn primary-btn `}
            bType="button"
            bValue={COMMON_TEXT.LOGIN}
            clickHandler={!rmnError && handleGetOtpClick}
            disabled={getOtpIsDisabled}
          />
        </div>
        {/* {!isSubscriptionDiscount(this.props.history) && <div className="rmn-not-now" onClick={() => notNow(this.props)}>
              Not now
            </div> } */}
        <div className="loginMdalFooter">
          <div>Not an <span className={"blue-text font-16"}><a href="https://www.akashdth.com" target="_blank" className={"blue-text font-14"}>AKASH DTH</a></span> user yet?</div>
          <div>Call <span className={"blue-text font-16"}>{isMobilecheck ? <a href={ishrefOnMobile} className={"blue-text font-14"}>16442</a> : "16442"}</span> or visit <span className={"blue-text font-14"}><a href="https://www.akashdth.com" target="_blank" className={"blue-text font-14"}>www.akashdth.com</a></span></div>
        </div>
      </div>
    );
  }
}

RMNComponent.propTypes = {
  handleChange: PropTypes.func,
  handleGetOtpClick: PropTypes.func,
  closeLoginModel: PropTypes.func,
  rmn: PropTypes.string,
  rmnError: PropTypes.string,
  getOtpIsDisabled: PropTypes.bool,
  configResponse: PropTypes.object,
};

export default compose(withRouter)(RMNComponent);
