export default {
    EVENT: {
        LOGIN_INITIATE: 'LOGIN-INITIATE',
        LOGIN_ENTER: 'LOGIN-ENTER',
        LOGIN_OTP_REQUESTED: "LOGIN-OTP-REQUESTED",
        LOGIN_OTP_INVOKE: "LOGIN-OTP-INVOKE",
        LOGIN_OTP_RESEND: "LOGIN-OTP-RESEND",
        LOGIN_OTP_ENTER: "LOGIN-OTP-ENTER",
        LOGIN_SUCCESS: "LOGIN-SUCCESS",
        LOGIN_FAILED: "LOGIN-FAILED",
        COMPLETE_REGISTRATION: "CompleteRegistration",
        SIGN_UP: "SIGN-UP",
        PACK_SELECTION_INITIATE: "PACK-SELECTION-INITIATE",
        PACK_SELECTED: "PACK-SELECTED",
        SUBSCRIPTION_SUCCESS: "SUBSCRIPTION-SUCCESS",
        SUBSCRIBE_SUCCESS_NEW: "SUBSCRIBE-SUCCESS-NEW",
        SUBSCRIBE_SUCCESS_REPEAT: "SUBSCRIBE-SUCCESS-REPEAT",
        SUBSCRIBE_SUCCESS_MODIFY_PACK: "SUBSCRIBE-SUCCESS-MODIFY-PACK",
        SUBSCRIBE_FAILED: "SUBSCRIBE-FAILED",
        PLAY_CONTENT_PREMIUM: "PLAY-CONTENT-PREMIUM",
        PLAY_CONTENT_PREMIUM_VTR75: "PLAY-CONTENT-PREMIUM-VTR75",
        PLAY_CONTENT_PREMIUM_VTR50: "PLAY-CONTENT-PREMIUM-VTR50",
        SUBSCRIBE_SUCCESS: "SUBSCRIBE-SUCCESS",
        PURCHASE: "Purchase"
    },
    VALUE: {
        TATA_SKY_SUBSCRIBER: "AkashGo SUBSCRIBER",
        HOME: 'Home',
        PLAY_CLICK: 'Play-Click',
        RMN: 'RMN',
        OTP: 'OTP',
        HAMBURGERMENU: 'HAMBURGERMENU',
        SUBSCRIBE: 'SUBSCRIBE',
        PLAYBACK: 'PLAYBACK',
        HAMBURGER_SUBSCRIBE:"Hamburger-Subscribe-CTA",
        HOME_SUBSCRIBE:"Home-Subscribe-CTA",
        CURRENCY: "INR",
        VALUE: "value",
        LOGGED_IN: "Logged-In",
        NON_LOGGED_IN: "Non-Logged-In",
        INR: "INR",
        PAID: "PAID",
        REPEAT: "REPEAT",
        MODIFY: "MODIFY",
        NEW: "NEW",
        FRESH: "FRESH",
        MSALES: "mSales"
    },
    PARAMETER: {
        SOURCE: 'SOURCE',
        TITLE: 'TITLE',
        TYPE: "TYPE",
        AUTH: "AUTH",
        VALUE: "VALUE",
        REASON: "REASON",
        CURRENCY: "currency",
        PARTNER: "PARTNER",
        CONTENT_PARTNER: "CONTENT-PARTNER",
        RAIL_TITLE: "RAIL-TITLE",
        CONTENT_TITLE: "CONTENT-TITLE",
        GENRE: "GENRE",
        RAIL_POSITION: "RAIL-POSITION",
        CONTENT_POSITION: "CONTENT-POSITION",
        CONTENT: 'CONTENT',
        POSITION: 'POSITION',
        CONTENT_TYPE: "CONTENT-TYPE",
        FREE_CONTENT: "FREE-CONTENT",
        PARTNER_NAME: "PARTNER-NAME",
        CONTENT_LANGUAGE: "CONTENT-LANGUAGE",
        LANGUAGE: "LANGUAGE",
        DURATION_SECONDS: "DURATION-SECONDS",
        DURATION_MINUTES: "DURATION-MINUTES",
        CONTENT_CATEGORY: "CONTENT-CATEGORY",
        CONTENT_PARENT_TITLE: "CONTENT-PARENT-TITLE",
        PACK_NAME: "PACK-NAME",
        PACK_PRICE: "PACK-PRICE",
        PACK_DURATION: "PACK-DURATION",
        PROMO_CODE: "PROMO-CODE",
        PAYMENT_MODE: "PAYMENT-MODE",
        PACK_ID: "PACK-ID",
        USER_LOGIN_STATE: "USER-LOGIN-STATE",
        LISTING_TYPE: "LISTING-TYPE",
        OLD_PACK_ID: "OLD-PACK-ID",
        PACK_TYPE: "PACK-TYPE",
        CONTENT_GENRE: "CONTENT-GENRE",
        SUBSCRIBE_TYPE: "SUBSCRIBE-TYPE",
        PACK_MOD_TYPE: "PACK-MOD-TYPE",
    },
    ANALYTICS: "FIREBASE",
};
