export const ACTION = {
  PACK_LISTING: "PACK_LISTING",
  GET_ACCOUNT_BALALNCE: "GET_ACCOUNT_BALALNCE",
  GET_CURRENT_SUBSCRIPTION_INFO: "GET_CURRENT_SUBSCRIPTION_INFO",
  CHECK_CALLING_SUBSCRIPTION_API: "CHECK_CALLING_SUBSCRIPTION_API",
  VALIDATE_SELECTED_PACK: "VALIDATE_SELECTED_PACK",
  ADD_NEW_PACK: "ADD_NEW_PACK",
  MODIFY_EXISTING_PACK: "MODIFY_EXISTING_PACK",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  REVOKE_SUBSCRIPTION: "REVOKE_SUBSCRIPTION",
  SET_UPDATED_TENURE: "SET_UPDATED_TENURE",
  NOTLOGGEDIN_SUBSCRIPTION: "NOTLOGGEDIN_SUBSCRIPTION",
  MINI_SUBSCRIPTION: "MINI_SUBSCRIPTION",
  CLEAR_PACK_LIST: "CLEAR_PACK_LIST",
  PORTAL_PACK_LINK: "PORTAL_PACK_LINK",
  MIGRATE_USER_INFO: "MIGRATE_USER_INFO",
  CAMPAIGN_PAGE_DATA: "CAMPAIGN_PAGE_DATA",
  CAMPAIGN_BANNER_DATA: "CAMPAIGN_BANNER_DATA",
  USER_ELIGIBILE_DATA: "USER_ELIGIBILE_DATA",
  REGIONAL_NUDGE_STATUS: "REGIONAL_NUDGE_STATUS",
};

export const PREMIUM = "premium vip" || "premium vip";

export const TENURE_MSG = "change tenure message";

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  DEACTIVE: "DEACTIVE",
  EXPIRED: "EXPIRED",
};

export const ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  DEACTIVATED: "DEACTIVATED",
  DEACTIVE: "DEACTIVE",
  CANCELLED: "CANCELLED",
  WRITTEN_OFF: "WRITTEN_OFF",
  TEMP_SUSPENSION: "TEMP_SUSPENSION",
  SUB_STATUS_PARTIALLY_DUNNED: "PARTIALLY DUNNED",
  INACTIVE: "INACTIVE",
  PENDING: "PENDING",
  DTH_STATUS: "Non DTH User"
};

export const PRIME_STATUS = {
  ACTIVATED: "ACTIVATED",
  CANCELLED: "CANCELLED",
  CANCELLED_INITIATED: "CANCELLED_INITIATED",
  SUSPENDED: "SUSPENDED"
}

export const PACK_VALIDATE_DELAY_WAIT_TIME = 10000;

export const DISCOUNT_SOURCE = 'discount';

export const TICK_TICK = {
  SUCCESS: "SUCCESS",
  LOGIN: "LOGIN",
  FAIL: "FAIL",
  EXISTING_LOGIN: "EXISTINGLOGIN",
}

export const JOURNEY_SOURCE = {
  MYPLAN_CHANGE: "MYPLAN#CHANGE",
  HOME_CONTENT: "HOME#CONTENT",
  MYPLAN_TENURE: "MYPLAN#TENURE",
  DRAWER_MYOP: "DRAWER#MYOP",
  DRAWER_CYOP: "DRAWER#CYOP",
  CURATED_PACK_SELECTION: "curated-pack-selection",
  CONTENT_PLAY: "content-play",
  MY_PLAN_EDIT: "my-plan-edit",
  MY_PLAN_REGIONAL: "MYPLAN#REGIONAL"
}

export const PACK_NAME = 'pack-name'

export const POLLING_MAX_COUNT = 30

