import {Link} from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

export const BreadCrumbs = (props) => {
  // conditional check for 'HOME', 'home', or '/'
  const shouldRender = !(props.link && typeof props.link === 'string' && (props.link.toLowerCase().includes("home") || props.link.includes("/")));

    return (
        <div className="bread-crumb">
            {
                props.link && shouldRender && (
                <>
                    <div className={`first ${props.className}`}>
                        <i className="icon-Path" />
          </div>
          {props.path !== "id" ? (
            props.breadCrumbClick ? (
              <div
                className="fetch"
                onClick={() => props.breadCrumbClick(true)}
              >
                <div className={`second ${props.className}`}>
                  {props.link.split("GO").map((part, index) => (
                    <>
                      {part}
                      {index < props.link.split("GO").length - 1 && (
                        <span style={{ fontStyle: "italic", fontWeight: 600 }}>
                          GO
                        </span>
                      )}
                    </>
                  ))}
                </div>           
                   </div>
            ) : (
         
         <Link to={props.path} className="fetch">
                <div className={`second ${props.className}`}>
                  {props.link.split("GO").map((part, index) => (
                    <>
                      {part}
                      {index < props.link.split("GO").length - 1 && (
                        <span style={{ fontStyle: "italic", fontWeight: 600 }}>
                          GO
                        </span>
                      )}
                    </>
                  ))}
                </div>
              </Link>
            )
          ) : (
            <div className={`second ${props.className}`}>
              {props.link.split("GO").map((part, index) => (
                <>
                  {part}
                  {index < props.link.split("GO").length - 1 && (
                    <span style={{ fontStyle: "italic", fontWeight: 600 }}>
                      GO
                    </span>
                  )}
                </>
              ))}
            </div>
          )}
        </>
      )}
        </div>
    )
};

BreadCrumbs.propTypes = {
    history: PropTypes.object,
    path: PropTypes.string,
    link: PropTypes.string,
    className: PropTypes.string,
    breadCrumbClick: PropTypes.func,
};