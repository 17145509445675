import React from 'react';
import PropTypes from "prop-types";

// Lala -- require for api case 
import { connect } from "react-redux";	
// import PropTypes from "prop-types";	
import { compose } from "redux";	
import { withRouter } from "react-router";
import {user_Info} from "@src/jsonfile.json"; // for new code
// end -- require for api case 

import {SOCIAL_MEDIA_URL} from '@constants'; // old code

import Facebook from "@assets/images/facebook.svg";
import Whatsapp from "@assets/images/whatsapp.svg";
import Instagram from "@assets/images/instagram.svg";
import twitter from "@assets/images/twitter.svg";
import youtube from "@assets/images/you-tube.svg";
import './style.scss';
import DATALAYER from '@utils/constants/dataLayer';
import {fireFooterClickEvent} from '@utils/common';

class ConnectUs extends React.Component {
    render() {
        const {showTitle} = this.props;
        return (
            <div className='connect-link'>
                {/* Old code */}
                {/* {showTitle && <span className='connect-us-title'>Connect with us</span>} */} 

                {/* for the new JSON integration */}
                {showTitle && <span className='connect-us-title 2'>{user_Info.Connect_with_us}</span>}

                <span className={"connect-us-btn"}>
                    <a 
                    href= {SOCIAL_MEDIA_URL.FACEBOOK} // old
                    // {this.props.getCmsResponse?.cmsData?.[0]?.logo}  - New code
                    
                    onClick={() => fireFooterClickEvent(DATALAYER.VALUE.FACEBOOK)}
                       target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="google-play-store"/>
                    </a>
                    <a href={user_Info.SOCIAL_MEDIA_URL.INSTAGRAM}
                    //{SOCIAL_MEDIA_URL.INSTAGRAM}
                     onClick={() => fireFooterClickEvent(DATALAYER.VALUE.INSTAGRAM)}
                       target="_blank" rel="noreferrer">
                        <img src={Instagram} alt="app-store"/>
                    </a>

                    <a href= {user_Info.SOCIAL_MEDIA_URL.TWITER} 
                    //{this.props.getCmsResponse?.cmsData?.[0]?.logo} // New Dyanmic API code                   
                    // {SOCIAL_MEDIA_URL.WHATSAPP} 
                    
                    onClick={() => fireFooterClickEvent(DATALAYER.VALUE.TWITER)}
                       target="_blank" rel="noreferrer">
                        <img src={twitter} alt="app-store"/>
                    </a>

                    <a href= {user_Info.SOCIAL_MEDIA_URL.YOUTUBE} 
                    //{this.props.getCmsResponse?.cmsData?.[0]?.logo} // New Dyanmic API code                   
                    // {SOCIAL_MEDIA_URL.WHATSAPP} 
                    
                    onClick={() => fireFooterClickEvent(DATALAYER.VALUE.YOUTUBE)}
                       target="_blank" rel="noreferrer">
                        <img src={youtube} alt="app-store"/>
                    </a>
                  
                </span>
            </div>
        )
    }
}

ConnectUs.propTypes = {
    showTitle: PropTypes.bool,
    history: PropTypes.object,
    // Lala
     getCmsResponse:PropTypes.object
}

// Lala
const mapStateToProps = (state) => { 	
    return{         	
   
    getCmsResponse: state.fetchCmsReducer, 	
}}; 	
const mapDispatchToProps = (dispatch) => ({ 	
    getCmsDetails: (value) => dispatch(getCmsDetails(value))	
    	
});
// export default ConnectUs;

// Lala 
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConnectUs);