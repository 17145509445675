import React, { Component } from "react";
import { isMobile, isUserloggedIn } from "@utils/common";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showSplash, hideSplash } from "@src/action";
import get from "lodash/get";
import { getKey, setKey } from "@utils/storage";
import { LOCALSTORAGE } from "@constants";
import { fetchPubnubHistory } from "@containers/Home/APIs/actions";
import { URL } from "@constants/routeConstants";
import "./style.scss";
import { BROWSER_TYPE } from "@constants/browser";
import { getSystemDetails } from "@utils/browserEnvironment";

// Lala
import { user_Info } from "@src/jsonfile.json";

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        background:
          "linear-gradient(69.87deg, #070316 43.33%, #130837 71.53%); !important",
        display: "none",
      },
    };
    this.myRef = React.createRef();
    this.systemDetail = "";
  }
  componentDidMount = async () => {
    console.log("splash-loading", this.myRef);
    this.systemDetail = getSystemDetails();
    let deepLinked = JSON.parse(getKey(LOCALSTORAGE.DEEPLINK)) === true;
    let userInfo2 = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    // localStorage.setItem("disabledBack", false);
    if (!deepLinked) {
      this.props.showSplash();
    }
    // debugger;
    // let hideSplashS = JSON.parse(getKey("logout_hideSPlash"));
    // if (hideSplashS) {
    //   this.props.hideSplash();
    //   localStorage.removeItem("logout_hideSPlash");
    // }
    // setKey(LOCALSTORAGE.HIDE_SPLASH, false);
    setKey(LOCALSTORAGE.DEEPLINK, JSON.stringify(false));
  };

  componentDidUpdate = () => {
    // localStorage.setItem("disabledBack", false);
    // setKey(LOCALSTORAGE.HIDE_SPLASH, false);
    if (this.myRef.current && this.props.splash) {
      this.myRef.current.addEventListener("playing", () => {
        this.setState({
          style: {
            background:
              "linear-gradient(69.87deg, #070316 43.33%, #130837 71.53%); !important",
            display: "block",
          },
        });
      });

      this.myRef.current.play();
      this.myRef.current.addEventListener("ended", () =>
        this.props.hideSplash()
      );
      this.myRef.current.addEventListener("error", () =>
        this.props.hideSplash()
      );
    }
  };

  showSplashVideo = () => {
    let paramData = new URLSearchParams(location.search);
    let cartId = paramData.get("cartId");
    let statusInfo = paramData.get("status");
    let tickTick = paramData.get("tickTick");
    let tickTick2 = paramData.get("tickTick");
    const urlArr = location.pathname.split("/");
    let showSplash = [
      URL.PAGE_NOT_FOUND,
      URL.BEST_VIEW_MOBILE,
      URL.APP_INSTALL,
      URL.SUBSCRIPTION_TRANSACTION,
      URL.SUBSCRIPTION_TRANSACTION_REDIRECT,
      URL.HELP_CENTER,
      URL.TRANSACTIONS,
      URL.SUBSCRIPTION_CAMPAIGN,
      URL.SUBSCRIPTION_DISCOUNT,
      URL.SEE_ALL,
      URL.CONTINUE_WATCHING_SEE_ALL,
      URL.DETAIL,
      URL.PLAYER
    ].includes(urlArr[1]);
    if ((cartId && statusInfo) || tickTick || statusInfo) {
      return false;
    }
    return !showSplash;
  };

  render() {
    let { location } = this.props;
    // Mobile devices in portrait mode
    const mobilePortrait376 = window.matchMedia(
      "(max-width: 390px) and (orientation: portrait)"
    );

    const mobilePortrait414 = window.matchMedia(
      "(min-width: 391px) and (max-width: 450px) and (orientation: portrait)"
    );

    const mobilePortrait = window.matchMedia(
      "(min-width: 451px) and (max-width: 600px) and (orientation: portrait)"
    );

    // Mobile devices in landscape mode
    const mobileLandscape = window.matchMedia(
      "(min-width: 600px) and (max-width: 767px) and (orientation: landscape)"
    );

    // Tablets in portrait mode
    const tabletPortrait = window.matchMedia(
      "(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)"
    );

    // Tablets in landscape mode
    const tabletLandscape = window.matchMedia(
      "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)"
    );

    function applyResponsiveDesign() {
      console.log("abcd---",mobilePortrait376);
      
      if (mobilePortrait376.matches) {
        //376
       return <source
        src={user_Info.Splash_Logo.Logo_android_portrait_2}
        type="video/mp4"
      />
      } else if (mobilePortrait414.matches) {
        //414
        return <source
        src={user_Info.Splash_Logo.Logo_android_portrait}
        type="video/mp4"
      />
      } else if (mobilePortrait.matches) {
        // Apply styles or functions for mobile portrait
        return <source
        src={user_Info.Splash_Logo.Logo_tab_portrait}
        type="video/mp4"
      />
       
      } else if (mobileLandscape.matches) {
        // Apply styles or functions for mobile landscape
        return <source
        src={user_Info.Splash_Logo.Logo_tab_landscap}
        type="video/mp4"
      />
      } else if (tabletPortrait.matches) {
        // Apply styles or functions for tablet portrait
        return <source
        src={user_Info.Splash_Logo.Logo_tab_8_landscape}
        type="video/mp4"
      />
      } else if (tabletLandscape.matches) {
        // Apply styles or functions for tablet landscape
        return <source
        src={user_Info.Splash_Logo.Logo_tab_8_landscape}
        type="video/mp4"
      />
      } else {
        // Default styles or functions
        return <source
        src={user_Info.Splash_Logo.Logo_tab_10_landscape}
        type="video/mp4"
      />
      }
    }

    return (
      <div>
        {this.systemDetail.browser !== BROWSER_TYPE.SAFARI && 
        this.props.splash ? (
          this.showSplashVideo() ? (
            <div
              className="fullscreen-bg"
              // style={{ 'background': $backgroundAllPage; }}
            >
              <video
                className="fullscreen-bg__video"
                muted
                ref={this.myRef}
                autoPlay="autoPlay"
                style={this.state.style}
                playsInline
                onError={this.props.hideSplash}
              >
                {/*<source src="../../assets/images/splash.ogv" type="video/ogg" />*/}
                {/* {window.innerWidth>414? <source src="../../assets/images/splashLarge.mp4" type="video/mp4" />:
                       <source src="../../assets/images/splashSmall.mp4" type="video/mp4" />} */}

                {applyResponsiveDesign()}

                {/* <source src="../../assets/images/splash.webm" type="video/webm" /> */}
              </video>
            </div>
          ) : null
        ) : null}
      </div>
    );
  }
}

Splash.propTypes = {
  showSplash: PropTypes.func,
  splash: PropTypes.bool,
  hideSplash: PropTypes.func,
  fetchPubnubHistory: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    splash: get(state.commonContent, "splash"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        showSplash,
        hideSplash,
        fetchPubnubHistory,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
