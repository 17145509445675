import React, { useMemo } from "react";
import "./style.scss";
import Highlighter from "react-highlight-words";
import Search from '@assets/images/search.png';
import LiveDot from '@assets/images/livedot.png';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from "@utils/common";
import { SUGGESTOR } from "@components/Header/constants";
import store from "@src/store";

const AutoCompleteItem = ({ searchText, passingVal, item }) => useMemo(() => {
  const renderHighlighter = () => {
    return (
      <Highlighter
        searchWords={[searchText]}
        autoEscape={true}
        textToHighlight={capitalizeFirstLetter(item?.title)}
        highlightStyle={{ color: 'white', fontWeight: 'bold', background: 'transparent' }}
      />
    )
  }

  const renderValues = (item) => {
    switch (item?.suggestor) {
      case SUGGESTOR.KEYWORD_SUGGESTOR:
        return <KeywordComponent title={capitalizeFirstLetter(item?.title)} />
      case SUGGESTOR.TITLE_SUGGESTOR:
        return <TitleComponent
          item={item}
          language={item?.language[0]}
          genre={item?.genre[0]}
          releaseYear={item?.releaseYear}
          contentType={item?.contentType}
          isLive={item?.liveContent}
        />
      // search for provider is disabled here: will enable once BBA intergation is done: Shivam 
      case SUGGESTOR.PROVIDER_SUGGESTOR:
        return <ProviderComponent item={item} subText={item?.subText} suggestor={item.suggestor} />
      case SUGGESTOR.GENRE_SUGGESTOR:
        return <ProviderComponent item={item} subText={item?.subText} suggestor={item.suggestor} />
      case SUGGESTOR.LANGUAGE_SUGGESTOR:
        return <ProviderComponent item={item} subText={item?.subText} suggestor={item.suggestor} />
    }
  }

  const KeywordComponent = ({ title }) => (
    <span className="Keyword__wrapper">
      <img src={Search} alt="search" className="Keyword__wrapper-img" />
      <p className="AutoCompleteItem__title">{renderHighlighter() || title}</p>
    </span>
  );

  const liveVal = () => <span className="live-content"> Live |</span>
  const liveDot = () => <img src={LiveDot} className={'live-dot-image'} />

  const TitleComponent = ({ item, genre, title, language, contentType, releaseYear, isLive = true }) => (
    <div className="AutoCompleteItem"><span className="AutoCompleteItem__img">
      <img src={item?.image} alt="" />
    </span>
      <div search="true" className="AutoCompleteItem__content">
        <span className="AutoCompleteItem__title auto_id">{renderHighlighter() || title} </span>
        <p className="AutoCompleteItem__title">{isLive && liveDot()} {isLive && liveVal()}  {`${genre} | ${contentType} | ${releaseYear} | ${language}`}</p>
      </div>
    </div>
  )

  const renderImage = (suggestor, backgroundImage, image, title) => {
    if (suggestor === SUGGESTOR.LANGUAGE_SUGGESTOR) {
      return (
        <span className={`AutoCompleteItem__img autocomplete-background-image`}
          style={{
            background: backgroundImage,
          }}
        >
          <img src={image} className={'top-image'} alt="" />
        </span>
      )
    } else if (suggestor === SUGGESTOR.GENRE_SUGGESTOR) {
      return (
        <span className={`AutoCompleteItem__img autocomplete-background-image genra-background`}
          style={{
            background: backgroundImage,
          }}
        >
          <img src={image} className={'top-image'} alt="" />
          <p>{title}</p>
        </span>
      )
    }
    else if (suggestor === SUGGESTOR.PROVIDER_SUGGESTOR) {
      return (<span className="AutoCompleteItem__img">
        <img className="autocomplete-background-image_provider" src={image} alt="" />
      </span>)
    }
  }

  const providerImage = (suggestor, image) => {
    const { headerDetails } = store.getState();
    const configResponse = headerDetails?.configResponse?.data?.config;
    if (suggestor === SUGGESTOR.PROVIDER_SUGGESTOR) {
      return configResponse?.providerLogo[(item?.provider ?? "").toUpperCase()]?.logoRectangular;
    } else {
      return image;//#9C27B0
    }
  }
  
  const backGroundImage = (suggestor, bkimage) => {
    if (suggestor === SUGGESTOR.LANGUAGE_SUGGESTOR || suggestor === SUGGESTOR.GENRE_SUGGESTOR) {
      return bkimage ? `url(${bkimage})` : "linear-gradient(270deg, #252a6300, #252a63)"//`${url(bkimage)}`
    } else {
      return "linear-gradient(90deg, #252A63, #252A63)";;//#9C27B0
    }
  }

  const ProviderComponent = ({ item, subText, suggestor }) => (
    <div className="AutoCompleteItem">
      {renderImage(suggestor, backGroundImage(suggestor, item?.backgroundImage), providerImage(suggestor, item.image), item?.title)}
      <div search="true" className="AutoCompleteItem__content">
        <span className="AutoCompleteItem__title">{renderHighlighter() || title} </span>
        <p className="AutoCompleteItem__title">{subText}</p>
      </div>
    </div>
  )

  return (
    <div search="true"
      className="autocomplete-item-wrapper"
      onClick={
        () => passingVal({
          title: item?.title,
          suggestor: item?.suggestor,
          partnerId: item.partnerId,
          contentType: item?.contentType,
          id: item?.id,
          item: item,
        })}>
      {
        renderValues(item)
      }
    </div>
  );
}, [searchText]);

export default AutoCompleteItem;


AutoCompleteItem.propTypes = {
  item: PropTypes.any,
  passingVal: PropTypes.func,
  searchText: PropTypes.string,
}